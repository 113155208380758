.table tr th {
    padding: 4px;
    position: sticky;
    top: 0
}

.table tr th:hover {
    /* background-color: #16213E; */
    background-color: #406c91;
    transition: background-color 0.5s;
}

.table tr td {
    padding: 4px;
}

.table thead { 
    /* background-color: #0F3460; */
    background-color: #50799B;
    font-weight: 200;
    font-size: medium;
    color: #ECFEFF;
}

.small-font{
    font-size: 14px;
}

