/* PagingComponent.css */

.pagination-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: justify;
    margin-top: 20px;
}

.pagination {
    display: inline-block;
    list-style: none;
    padding: 0;
}

.pagination li {
    display: inline;
    margin: 0 1px;
}

.pagination button {
    padding: 4px 8px;
    background-color: #90e0ef;
    color: white;
    border: 1px solid #90e0ef;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #00b4d8;
}

.pagination button:disabled {
    background-color: #d9d9d9;
    color: #6c757d;
    border: 1px solid #d9d9d9;
    cursor: not-allowed;
}

/* Styling for the active class */
.pagination li.active button {
    background-color: #219ebc;
    border: 1px solid #219ebc;
}

.rounded-left {
    border-radius: 4px 0px 0px 4px;
}

.rounded-right {
    border-radius: 0px 4px 4px 0px;
}

/* Styling for the "go-to-page" section */
.pagination input {
    display: flex;
    width: 60px;
    padding: 0 5px;
    align-items: center;
}

.pagination button {
    background-color: #90e0ef;
    color: white;
    border: 1px solid #90e0ef;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #219ebc;
    border: 1px solid #219ebc;
}

.flex-child {
    flex: 1;
}

.margin-x {
    margin: 4px
}

.pagination-2 {
    display: inline-block;
    list-style: none;
    padding: 0;
    
}

/* .pagination-2 button {
    border: 0;
    cursor: pointer;
    color: #eaeef3;
    background-color: #5a5c69;
    border: 1px solid #5a5c69;
    height: 32px;
} */

/* .pagination-2 button:hover {
    border: 0;
    cursor: pointer;
    color: #eaeef3;
    background-color: #444650;
    border: 1px solid #444650;
    height: 32px;
    transition: background-color 0.5s;
} */

.pagination-2 button {
    border: 0;
    cursor: pointer;
    color: #eaeef3;
    background-color: #50799B;
    border: 1px solid #50799B;
    height: 28px;
    padding: 0px 8px;
}

.pagination-2 button:hover {
    border: 0;
    cursor: pointer;
    color: #eaeef3;
    background-color: #406c91;
    border: 1px solid #406c91;
    height: 28px;
    transition: background-color 0.5s;
}

.pagination-2 button:disabled {
    background-color: #e6e6e9;
    border: 1px solid #e6e6e9;
    cursor: not-allowed;
    color: #999a9b;
}

.big-font {
    font-size: 24px;
    color: #5a5c69;
}

.select-option {
    height: 28px;
    border-radius: 4px;
    border: 1px solid gray; 
    margin-top: 4px;   
}

.input-box {
    height: 28px;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid gray;
    padding: 0 4px;    
}

.small-font{
    font-size: 14px;
}