.height-100vh {
    height: 100vh;
}

.delete-confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.delete-confirmation-modal p {
    margin-bottom: 15px;
}

.delete-confirmation-modal .yes {
    margin: 0 10px;
    padding: 8px 16px;
    cursor: pointer; 
    border: none;
    border-radius: 4px;
    font-size: 14px;
    background-color: #ff0000;
    color: white;
}

.delete-confirmation-modal .yes:hover {
    background-color: #cc0000;
}

.delete-confirmation-modal .no {
    margin: 0 10px;
    padding: 8px 16px;
    cursor: pointer;    
    border: none;
    border-radius: 4px;
    font-size: 14px;
    background-color: #33cc33;
    color: white;
}

.delete-confirmation-modal .no:hover {
    background-color: #29a329;
}