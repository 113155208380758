* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ddr .dropdown-toggle {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #d1d3e2;
    text-align: left;
}

.ddr .dropdown-menu {
    background-color: white;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #d1d3e2;
}

.ddr .dropdown-item {
    color: black;
    /* Set text color as needed */
}

.ddr .form-control {
    border: 1px solid #e6e4e4;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
}