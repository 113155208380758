.accept-yes-label {
    color: #1597BB;
    font-weight: 200;
}

.accept-no-label {
    color: #6b6b6b;
}

.small-font{
    font-size: 14px;
}